import * as duration from 'duration-fns';
import subMinutes from 'date-fns/subMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';

export const dateOnly = (dateObj) => {
  return new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    0,
    0,
    0,
    0
  );
};

export const durationToString = (goalDateSelect) => {
  const parsedDuration = duration.parse(goalDateSelect);
  if (parsedDuration.years) {
    return `Last ${parsedDuration.years} year${
      parsedDuration.years > 1 ? 's' : ''
    }`;
  }
  if (parsedDuration.months) {
    return `Last ${parsedDuration.months} month${
      parsedDuration.months > 1 ? 's' : ''
    }`;
  }
  if (parsedDuration.weeks) {
    return `Last ${parsedDuration.weeks} week${
      parsedDuration.weeks > 1 ? 's' : ''
    }`;
  }

  return '';
};

// input: a, b => output: a - | a - b |
export const mirrorDate = (dateLeft, dateRight) => {
  const diffInMinutes = differenceInMinutes(dateRight, dateLeft);

  return subMinutes(dateLeft, diffInMinutes);
};

export function getYMD(date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}

export function getOppositeDate(durationISO) {
  const period = duration.parse(durationISO);
  const oppositePeriod = {};
  for (let [key, value] of Object.entries(period)) {
    oppositePeriod[key] = -value;
  }
  return duration.apply(new Date(), oppositePeriod);
}

export function isBeforeBitcoinExistence({ year, month, day }) {
  if (year < 2010) return true;
  if (year == 2010 && month < 7) {
    return true;
  }
  if (year == 2010 && month == 7 && day < 17) return true;
  return false;
}

export function formatDateForHistoricApi(dateObject) {
  const { year, month, day } = dateObject;

  // Pad month and day with leading zero if they are less than 10
  const paddedMonth = month < 10 ? `0${month}` : `${month}`;
  const paddedDay = day < 10 ? `0${day}` : `${day}`;

  return `${year}-${paddedMonth}-${paddedDay}`;
}

export function getDateFromString(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  // JavaScript's getMonth() function returns a 0-based month, so we add 1 to get a 1-based month
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return { year, month, day };
}
