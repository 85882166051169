import styled from 'styled-components';
import { lighten } from 'polished';

export const FormButton = styled.button(({ $isSecondary, theme }) => ({
  border: 'none',
  cursor: 'pointer',
  flex: 1,
  flexShrink: 0,
  color: $isSecondary ? theme.colors.text : theme.colors.textInverse,
  fontSize: 'inherit',
  fontWeight: 'bold',
  padding: '1rem 2rem',
  background: $isSecondary ? theme.colors.secondary : theme.colors.primary,
  backdropFilter: 'blur(4px)',
  '-webkit-backdrop-filter': 'blur(4px)',
  borderRadius: '5px',
  minHeight: '40px',

  ['&:hover']: {
    background: $isSecondary ? lighten(0.1, theme.colors.secondary) : lighten(0.1, theme.colors.primary),
  },
}));
