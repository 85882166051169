import { useForm, useFormState } from 'react-final-form';

import { Box } from 'atomic-layout';
import { PricePoint } from './pricePoint';
import React, { useEffect, useState } from 'react';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import useCryptoValue from 'utils/useCryptoValue';

const sortByDate = (arr) =>
  arr.sort((a, b) => {
    return a.date > b.date ? 1 : -1;
  });

const PredictionInputs = ({ inputsData }) => {
  const form = useForm();
  const {
    values: { goalDate, currency, pricePredictions = [] },
  } = useFormState();
  const { data: btcPrices, isValidating } = useCryptoValue();
  const btcPriceInCurrency = btcPrices[currency];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isValidating) {
      setIsLoading(false);
    }
  }, [isValidating]);
  // TODO: Make a simple spinner.
  if (isLoading) return 'loading...';

  return (
    <Box flex flexDirection="column" gap={1} flexGrow={1}>
      {pricePredictions.map(({ date, value }, idx) => {
        const isFirst = idx === 0;
        if (isFirst && value === undefined) {
          value = btcPriceInCurrency;
        }
        const isLast = idx >= pricePredictions.length - 1;
        const isDateAdjacent =
          !isLast &&
          differenceInDays(pricePredictions[idx + 1].date, date) <= 1;
        const showAddButton = isFirst || !isDateAdjacent;

        return (
          <PricePoint
            dateLabel={isFirst && inputsData.futurePriceDateInputLabel}
            valueLabel={isFirst && inputsData.futurePricePriceInputLabel}
            key={idx}
            idx={idx}
            inputsData={inputsData}
            date={date}
            goalDate={goalDate}
            currency={currency}
            value={value}
            disableDate={isFirst}
            disableValue={isFirst}
            renderAddButton={showAddButton}
            renderRemoveButton={!isFirst}
            onChange={(newVal) => {
              form.change(
                'pricePredictions',
                sortByDate([
                  ...pricePredictions.slice(0, idx),
                  newVal,
                  ...pricePredictions.slice(idx + 1),
                ])
              );
            }}
            onAdd={() => {
              form.change('pricePredictions', [
                ...pricePredictions.slice(0, idx + 1),
                { date: addDays(date, 1), value: '' },
                ...pricePredictions.slice(idx + 1),
              ]);
            }}
            onRemove={() => {
              form.change('pricePredictions', [
                ...pricePredictions.slice(0, idx),
                ...pricePredictions.slice(idx + 1),
              ]);
            }}
          />
        );
      })}
    </Box>
  );
};

export default PredictionInputs;
