import { useCalculation } from 'components/content/calculator/calculationContext';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Field, useFormState } from 'react-final-form';
import { useMitt } from 'react-mitt';
import * as calculator from '../../../../atoms/calculator';
import { toBTC, toSat } from '../../../../utils/convertBtcSats';
import useCryptoValue from 'utils/useCryptoValue';
import {
  CurrencyInput,
  getUnitName,
  numberWithCommas,
  scientificToNumber,
} from '../../../../utils/currency';
import { useLogic } from '../../../../utils/logicContext';
import { FormattedInput, PercentInput, StyledField } from '../../../atoms/form';
import { FormButton } from '../../../atoms/form/formButton';
import Toggle from 'components/atoms/toggle';
import { StyledModal } from '../../../atoms/Modal';
import { Disconnect } from '../../../atoms/SVG';
import {
  StyledContent,
  StyledHeader,
  StyledSubTitle,
  StyledTitle,
} from '../../../custom-pricing-modal';
import { StyledFormContainer } from '../../../custom-pricing-modal/custom-pricing-modal';
import { StyledButton } from '../../../offline-modal';

function validateStack(currStack, isSats, inputProps, supplyLogic) {
  const { BTCSupplyLimit, SatsSupplyLimit } = supplyLogic;
  const { goalAmountBTCOverLimitError, goalAmountSatsOverLimitError } = inputProps;
  if (isSats && currStack >= Number(SatsSupplyLimit)) return goalAmountSatsOverLimitError;
  if (!isSats && currStack >= Number(BTCSupplyLimit)) return goalAmountBTCOverLimitError;
} 

// TODO: Move to a hook
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// TODO: Move to styles
export const AdvancedOptionsFormButton = ({ setShowAdvancedOptions }) => {
  return (
    <div style={{ display: 'flex' }}>
      <FormButton $isSecondary onClick={setShowAdvancedOptions}>
        Advanced Options
      </FormButton>
    </div>
  );
};

// TODO: Clean this up
export const AdvancedOptionsModal = ({
  isOpen,
  handleClose,
  increaseWithInflation,
  calculateFuturePurchasingPower,
  workOfflineSection,
  inputsData,
}) => {
  const [startingStack, setStartingStack] = useAtom(
    calculator.startingStackAtom
  );
  const [startingStackAvgBTC, setStartingStackAvgBTC] = useAtom(
    calculator.avgCostPerBTCPriceAtom
  );

  const {
    values: { isSatoshis },
  } = useFormState();
  const prevIsSatoshi = usePrevious(isSatoshis);
  const [hasAvgBtcBeenEdited, setHasAvgBtcBeenEdited] = useState(false);

  const { goal, useStackingAmount, currency = 'USD' } = useCalculation();
  const logic = useLogic();
  const { emitter } = useMitt();

  const { data } = useCryptoValue();
  const priceNow = data && data[currency];
  const avgBTCPriceToUse = !hasAvgBtcBeenEdited
    ? priceNow
    : startingStackAvgBTC;

  const format = (val) => {
    val = scientificToNumber(val || 0);
    return `${numberWithCommas(val)} ${getUnitName(val, isSatoshis)}`;
  };

  // TODO: Find a way to sort the value going from high to low when BTC to Sats is changed.
  useEffect(() => {
    prevIsSatoshi != isSatoshis && isSatoshis
      ? setStartingStack(Number(toSat(startingStack)))
      : setStartingStack(Number(toBTC(startingStack)));
  }, [isSatoshis]);

  const minStackingAmount =
    useStackingAmount || !goal ? (isSatoshis ? 1 : 0.00000001) : goal;
  const maxStackingAmount =
    useStackingAmount || !goal
      ? isSatoshis
        ? 1 * logic.SatsSupplyLimit
        : 1 * logic.BTCSupplyLimit
      : goal;

  const handleStartingStack = (e) => {
    const newVal = e.target.value;

    if (!!prevIsSatoshi) return setStartingStack(newVal);

    if (prevIsSatoshi != isSatoshis && isSatoshis)
      return setStartingStack(newVal);
    return setStartingStack(newVal);
  };

  const handleAvgBTCStackChange = (newVal) => {
    setHasAvgBtcBeenEdited(true);
    setStartingStackAvgBTC(newVal);
  };

  const isInflationDisabled =
    !increaseWithInflation && !calculateFuturePurchasingPower;

  return (
    isOpen &&
    ReactDOM.createPortal(
      <StyledModal title="Advanced Options" onClose={handleClose}>
        <StyledContent $width={350}>
          <StyledHeader $col>
            <StyledTitle>Advanced Options</StyledTitle>
            <StyledSubTitle>
              {useStackingAmount
                ? inputsData.predictByInvestmentAmount
                : inputsData.predictByGoal}
            </StyledSubTitle>
          </StyledHeader>
          <StyledFormContainer>
            <Field
              name="currentStack"
              type="number"
              validate={(val) => {
                if (Number(val) < 0) return 'Value cannot be negative.';
              }}
            >
              {() => (
                <StyledField
                  label={inputsData.currentStackLabel}
                  // error={isCurrentStackError()}
                  error={validateStack(startingStack, isSatoshis, inputsData, logic)}
                  help={inputsData.helpTexts.startingStack}
                  upperRightContent={
                    <StyledButton
                      onClick={() => emitter.emit('show-offline-modal')}
                      $primary
                    >
                      <Disconnect />
                      {workOfflineSection?.buttonText}
                    </StyledButton>
                  }
                >
                  <FormattedInput
                    type="number"
                    noNegative
                    value={startingStack}
                    onChange={handleStartingStack}
                    id="currentStackInput"
                    step={isSatoshis ? 1 : 0.00000001}
                    min={minStackingAmount}
                    max={maxStackingAmount}
                    format={() => format(startingStack)}
                    $error={validateStack(startingStack, isSatoshis, inputsData, logic)}
                  />
                </StyledField>
              )}
            </Field>
            <StyledField
              label={inputsData.avgStackCostLabel}
              help={inputsData.helpTexts.avgStackCost}
            >
              <CurrencyInput
                type="number"
                id="avgBTCStackCostInput"
                currency={currency}
                noNegative
                step={1}
                value={avgBTCPriceToUse}
                min={0}
                max={maxStackingAmount}
                onChange={(e) =>
                  handleAvgBTCStackChange(e.target.valueAsNumber)
                }
              />
            </StyledField>
            <>
              <Field
                name="calculateFuturePurchasingPower"
                type="checkbox"
                initialValue={false}
              >
                {({ input, meta }) => (
                  <StyledField
                    style={{
                      ...(useStackingAmount && {
                        marginBottom: '0.1rem',
                        marginTop: '-0.5rem',
                      }),
                    }}
                    error={meta.touched && meta.error}
                  >
                    <Toggle
                      id="calculation-future-purchasing-power"
                      leftLabel=""
                      rightLabel={inputsData.CalculateFuturePurchasingPower}
                      checked={input.checked}
                      onChange={input.onChange}
                    />
                  </StyledField>
                )}
              </Field>
              {useStackingAmount && (
                <Field
                  name="increaseWithInflation"
                  type="checkbox"
                  initialValue={false}
                >
                  {({ input, meta }) => (
                    <StyledField
                      style={{ marginTop: '-1rem' }}
                      error={meta.touched && meta.error}
                    >
                      <Toggle
                        id="icrease-with-inflation"
                        leftLabel=""
                        rightLabel={inputsData.increaseWithInflationText}
                        checked={input.checked}
                        onChange={input.onChange}
                      />
                    </StyledField>
                  )}
                </Field>
              )}
            </>
            <>
              <Field name="inflationPercent" initialValue={2}>
                {({ input, meta }) => (
                  <StyledField
                    isDisabled={isInflationDisabled}
                    label={inputsData.inflationRateLabel}
                    error={meta.touched && meta.error}
                    help={inputsData.helpTexts.inflationRate}
                  >
                    <PercentInput
                      isDisabled={isInflationDisabled}
                      {...input}
                      step={0.5}
                      $error={meta.touched && meta.error}
                    />
                  </StyledField>
                )}
              </Field>
            </>
            <FormButton style={{ width: '100%' }} onClick={handleClose}>
              Confirm
            </FormButton>
          </StyledFormContainer>
        </StyledContent>
      </StyledModal>,
      document?.body
    )
  );
};
