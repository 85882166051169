import { Box, Composition } from 'atomic-layout';
import { DatePicker, Input } from 'components/atoms/form';

import { CurrencyInput } from 'utils/currency';
import { FormIconButton } from 'components/atoms/Buttons';
import NextImage from "next/legacy/image";
import React from 'react';
import { dateOnly } from 'utils/date';
import format from 'date-fns/format';
import iconAdd from 'images/iconAdd.svg';
import iconRemove from 'images/iconRemove.svg';
import styled from 'styled-components';

const pricePointAreas = `
    dateInput priceInput buttons
`;

const PriceInputContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const PricePoint = ({
  idx,
  date,
  value,
  disableDate,
  disableValue,
  onChange,
  renderAddButton = true,
  renderRemoveButton = true,
  onAdd,
  onRemove,
  goalDate,
  currency,
  dateLabel,
  valueLabel
}) => {
  const gap = '1rem'

  return (
    <Composition
      areas={pricePointAreas}
      templateCols="minmax(33%, 1fr) minmax(10rem, 12rem) 60px"
      gap={1}
    >
      {({ DateInput, PriceInput, Buttons }) => (
        <>
          <DateInput flex style={{ minWidth: '130px', flexDirection: 'column', gap }}>
            {dateLabel && <div>{dateLabel}</div>}
            <DatePicker
                id={`priceinput-${idx}`}
                disabled={disableDate}
                value={format(date, 'yyyy-MM-dd', new Date())}
                onChange={(newDate) => onChange({ date: newDate, value })}
                minDate={dateOnly(new Date())}
            />
          </DateInput>
          <PriceInput flex style={{ flexDirection: 'column', gap }}>
            {valueLabel && <div>{valueLabel}</div>}
            <PriceInputContainer>
              <CurrencyInput
                  currency={currency}
                  value={value}
                  onChange={(e) => onChange({ date, value: e.target.value })}
                  disabled={disableValue}
                  min={0}
                  $error={value < 0}
              />
              <Buttons align="center">
                <Box flex gap="0.7rem" flexDirection="row" justifyContent="space-between">
                  {renderAddButton && (
                      <FormIconButton type="button" onClick={() => onAdd()}>
                        <NextImage
                            src={iconAdd}
                            alt="add"
                            height="15px"
                            width="15px"
                        />
                      </FormIconButton>
                  )}
                  {renderRemoveButton && (
                      <FormIconButton type="button" onClick={() => onRemove()}>
                        <NextImage
                            src={iconRemove}
                            alt="remove"
                            height="15px"
                            width="15px"
                        />
                      </FormIconButton>
                  )}
                </Box>
              </Buttons>
            </PriceInputContainer>
          </PriceInput>
        </>
      )}
    </Composition>
  );
};
