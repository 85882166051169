import {
  StyledButton,
  StyledContent,
  StyledDescription, StyledImage,
  StyledLI,
  StyledModal,
  StyledOrderedList,
  StyledTitle
} from './index';
import {Composition} from 'atomic-layout';
import DisconnectRawImage from '../../images/disconnect-image.svg';
import ReactDOM from 'react-dom';


const areasMobile = `
  description
  image
`

const areas = `
description image
`;

export default function RawOfflineModal({ data, onClose }) {
  const onModalClick = (e) => {
    if (e.target.classList.contains('close-on-click')) {
      onClose()
    }
  }

  return ReactDOM.createPortal(<StyledModal className="close-on-click" onClick={onModalClick}>
    <StyledContent>
      <Composition
          templateCols="minmax(0, 1fr)"
          areas={areasMobile} areasMd={areas}>
        {(Areas) => (
            <>
              <Areas.Description paddingBottom="2rem">
                <StyledTitle>{data?.title}</StyledTitle>
                <StyledDescription>{data?.description}</StyledDescription>
                {data?.steps?.length && <StyledOrderedList>
                  {data?.steps.map(step => (
                      <StyledLI key={step}>{step}</StyledLI>
                  ))}
                </StyledOrderedList>}
                <StyledButton onClick={onClose} $primary>{data?.buttonLabel}</StyledButton>
              </Areas.Description>
              <Areas.Image heightSm="25rem" heightMd="32rem" height="22rem" widthMd="37rem" widthLg="44rem">
                <StyledImage src={DisconnectRawImage.src}/>
              </Areas.Image>
            </>
        )}
      </Composition>
    </StyledContent>
  </StyledModal>, document.body)
}
