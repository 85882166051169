import { Select, StyledField } from 'components/atoms/form';
import { Field, useForm, useFormState } from 'react-final-form';
import { getCurrencySymbol, getLocaleCurrency } from 'utils/currency';

import { Composition } from 'atomic-layout';
import GoalDateField from './goalDate';
import GoalField from './goal';
import PricePointsInput from './pricePointsInput/pricePointsInput';
import React, { useState } from 'react';
import StackingAmountField from './stackingAmount';
import TabSwitch from 'components/atoms/TabSwitch';
import { onToggle as onToggleBTCSats } from 'utils/convertBtcSats';
import OfflineModal from '../../offline-modal';
import {
  AdvancedOptionsFormButton,
  AdvancedOptionsModal,
} from './advancedOptions';

const fields = `
    calculatorType
    bitcoinFormat
    currency
    stackingFrequency
    goal
    goalDate
    pricePredictions
    advancedModal
`;

const CalculatorInputs = ({
  inputsData,
  workOfflineSection,
  showPricePredictionModal,
  setShowPricePredictionModal,
}) => {
  const { helpTexts } = inputsData;
  const { values } = useFormState();
  const {
    useStackingAmount = true,
    increaseWithInflation = false,
    isSatoshis,
    calculateFuturePurchasingPower,
  } = values;
  const form = useForm();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  function handleCalculatorTypeChange(input) {
    const { onChange, checked } = input;
    form.change('increaseWithInflation', false);
    onChange(!checked);
  }
  
  return (
    <>
      <OfflineModal workOfflineSection={workOfflineSection} />
      <AdvancedOptionsModal
        handleClose={() => setShowAdvancedOptions(false)}
        isOpen={showAdvancedOptions}
        increaseWithInflation={increaseWithInflation}
        calculateFuturePurchasingPower={calculateFuturePurchasingPower}
        workOfflineSection={workOfflineSection}
        inputsData={inputsData}
      />
      <Composition areas={fields} gap={1} height="100%">
        {(Fields) => (
          <>
            <Fields.CalculatorType marginBottom={0.75}>
              <Field
                name="useStackingAmount"
                initialValue={
                  inputsData.defaults.calculator === 'predictByInvestment'
                }
                type="checkbox"
              >
                {({ input }) => (
                  <>
                    <TabSwitch
                      tabs={[
                        inputsData.defaults.calculator === 'predictByInvestment'
                          ? {
                              value: true,
                              label: inputsData.predictByInvestmentAmount,
                            }
                          : { value: false, label: inputsData.predictByGoal },
                        inputsData.defaults.calculator === 'predictByInvestment'
                          ? { value: false, label: inputsData.predictByGoal }
                          : {
                              value: true,
                              label: inputsData.predictByInvestmentAmount,
                            },
                      ]}
                      onChange={() => handleCalculatorTypeChange(input, inputsData)}
                      value={input.checked}
                      primary
                    />
                  </>
                )}
              </Field>
            </Fields.CalculatorType>
            <Fields.BitcoinFormat>
              <Field
                name="isSatoshis"
                initialValue={true}
                type="checkbox"
                format={(value) =>
                  value ? Number(value).toFixed(isSatoshis ? 0 : 8) : ''
                }
              >
                {({ input, meta }) => (
                  <>
                    <StyledField
                      label={inputsData.isSatoshisLabel}
                      error={meta.touched && meta.error}
                      help={helpTexts.isSatoshis}
                    >
                      <TabSwitch
                        tabs={[
                          { value: true, label: 'Satoshi' },
                          { value: false, label: 'Bitcoin' },
                        ]}
                        value={input.checked}
                        onChange={(newValue) => {
                          onToggleBTCSats(form, isSatoshis, newValue);
                          input.onChange(newValue);
                        }}
                      />
                    </StyledField>
                  </>
                )}
              </Field>
            </Fields.BitcoinFormat>
            <Fields.Currency>
              <Field
                name="currency"
                initialValue={getLocaleCurrency(inputsData.defaults.currency)}
              >
                {({ input, meta }) => (
                  <StyledField
                    label={inputsData.currenySelectorLabel}
                    error={meta.touched && meta.error}
                    help={helpTexts.currency}
                  >
                    <Select {...input}>
                      <option value="USD">
                        USD ({getCurrencySymbol('USD')})
                      </option>
                      <option value="GBP">
                        GBP ({getCurrencySymbol('GBP')})
                      </option>
                      <option value="EUR">
                        EUR ({getCurrencySymbol('EUR')})
                      </option>
                      <option value="JPY">
                        JPY ({getCurrencySymbol('JPY')})
                      </option>
                      <option value="AUD">
                        AUD ({getCurrencySymbol('AUD')})
                      </option>
                      <option value="CAD">
                        CAD ({getCurrencySymbol('CAD')})
                      </option>
                    </Select>
                  </StyledField>
                )}
              </Field>
            </Fields.Currency>
            <Fields.StackingFrequency>
              <Field
                name="stackingFrequency"
                initialValue={inputsData.defaults.stackingFrequency}
              >
                {({ input, meta }) => (
                  <StyledField
                    label={inputsData.stackingFrequencyLabel}
                    error={meta.touched && meta.error}
                    help={helpTexts.stackingFrequency}
                  >
                    <TabSwitch
                      tabs={[
                        { value: 'DAILY', label: 'Daily' },
                        { value: 'WEEKLY', label: 'Weekly' },
                        { value: 'MONTHLY', label: 'Monthly' },
                        { value: 'YEARLY', label: 'Yearly' },
                      ]}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  </StyledField>
                )}
              </Field>
            </Fields.StackingFrequency>
            <Fields.Goal>
              {useStackingAmount ? (
                <StackingAmountField inputsData={inputsData} />
              ) : (
                <GoalField inputsData={inputsData} />
              )}
            </Fields.Goal>
            <Fields.GoalDate>
              <GoalDateField inputsData={inputsData} />
            </Fields.GoalDate>
            <Fields.PricePredictions>
              <PricePointsInput
                inputsData={inputsData}
                showPricePredictionModal={showPricePredictionModal}
                setShowPricePredictionModal={setShowPricePredictionModal}
              />
            </Fields.PricePredictions>
            <Fields.AdvancedModal marginBottom={1.5}>
              <AdvancedOptionsFormButton
                setShowAdvancedOptions={() => setShowAdvancedOptions(true)}
              />
            </Fields.AdvancedModal>
          </>
        )}
      </Composition>
    </>
  );
};

export default CalculatorInputs;
