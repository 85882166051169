import { Field, useForm, useFormState } from 'react-final-form';
import { StyledField } from 'components/atoms/form';
import { FormButton } from 'components/atoms/form/formButton';
import React, { useEffect, useState } from 'react';
import isSameDay from 'date-fns/isSameDay';
import useCryptoValue from 'utils/useCryptoValue';
import {
  formatDateForHistoricApi,
  getOppositeDate,
  getYMD,
} from '../../../../../utils/date';
import {
  fetchHistoricBtcPrice,
  fetchMultipleHistoricBtcPrice,
} from '../../../../../utils/btcApi/fetchHistoricBtcPrice';
import { FormattedInput } from '../../../../atoms/form';
import CustomPricingModal from '../../../../custom-pricing-modal/custom-pricing-modal';
import { Select } from 'components/atoms/form/select';
import Checkbox from '../../../../atoms/Checkbox';
import * as duration from 'duration-fns';
import { differenceInDays } from 'date-fns';
import { StyledCustomPrices } from '../../../../atoms/CustomPrice';

const PricePointsInput = ({ inputsData, setShowPricePredictionModal }) => {
  const form = useForm();
  const {
    values: {
      growth,
      goalDateSelect,
      goalDate,
      currency,
      cagrDateSelect,
      cagrCustomDate,
      useStackingAmount,
      showPrediction,
      pricePredictions = [],
      customPrices,
    },
    errors: { growth: growthError },
  } = useFormState();
  const {
    cagrDateSelectOptions: _cagrDateSelectOptions,
    customGoalDateOptionLabel,
    helpTexts,
  } = inputsData;
  const [cagrDateSelectOptions, setCagrDateSelectOptions] = useState(
    _cagrDateSelectOptions
  );
  const [showCustomPricingModal, setShowCustomPricingModal] = useState(false);
  const { data: btcPrices = {} } = useCryptoValue();
  const [oldPrice, setOldPrice] = useState(null);
  const [loadingGrowthRate, setLoadingGrowthRate] = useState(false);
  const [increaseValueByPercentage, _setIncreaseValueByPercentage] =
    useState(false);
  const [cagrCustomDatePercentage, setCagrCustomDatePercentage] =
    useState(null);
  const [useCagrCustomDate, _setUseCagrCustomDate] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const btcPriceNow = currency ? btcPrices[currency] : btcPrices['USD'];

  const setUseCagrCustomDate = (val) => {
    form.change('showPrediction', !val);
    _setUseCagrCustomDate(val);
  };
  const setIncreaseValueByPercentage = (val) => {
    const defaultOption = getCAGRInitialValue(cagrDateSelectOptions);
    const growthRateToUse = inputsData.defaults.growthRate !== null ? inputsData.defaults.growthRate : defaultOption;
    if (val) {
      _setUseCagrCustomDate(!val);
      form.change('growth', growthRateToUse);
    }
    _setIncreaseValueByPercentage(val);
  };

  const calculateCagrPercentage = (price, diffMonthsOrDays, options) => {
    return Math.round(
      (Math.pow(
        btcPrices['USD'] / price,
        1 / (diffMonthsOrDays / (options?.diffInDays ? 365 : 12))
      ) -
        1) *
        100
    );
  };

  // Calls the old price api to get the value from selected date
  useEffect(() => {
    if (!cagrCustomDate) {
      return;
    }
    const formattedApiDate = formatDateForHistoricApi(getYMD(cagrCustomDate));
    fetchHistoricBtcPrice({ date: formattedApiDate }).then(({ USD: price }) => {
      const percentage = calculateCagrPercentage(
        price,
        Math.abs(differenceInDays(new Date(), cagrCustomDate)),
        { diffInDays: true }
      );
      form.change('showPrediction', false);
      setCagrCustomDatePercentage(percentage);
      form.change('growth', percentage);
    });
  }, [cagrCustomDate]);

  const setCagrCustomDate = (val) => {
    form.change('cagrCustomDate', val);
  };

  useEffect(() => {
    // If btcPriceNow is not available or if we've already fetched for this value, do nothing
    if (!btcPriceNow || hasFetched) {
      return;
    }

    setLoadingGrowthRate(true);

    const dates = _cagrDateSelectOptions.map((d) => {
      const newDate = getOppositeDate(d.durationISO);
      const period = duration.parse(d.durationISO);
      return {
        ymd: getYMD(newDate),
        diffMonths: period.years * 12 + period.months,
      };
    });

    fetchMultipleHistoricBtcPrice({ dates: dates.map((d) => d.ymd) })
      .then((priceRange) => {
        const newOptions = _cagrDateSelectOptions.map((option, index) => {
          option.price = priceRange[index]['USD'];
          option.percentage = calculateCagrPercentage(
            option.price,
            dates[index].diffMonths
          );
          return option;
        });
        const defaultOption = newOptions.find((opt) => opt.default);
        setCagrDateSelectOptions(newOptions);
        form.change('growth', defaultOption.percentage);
        // Mark as fetched
        setHasFetched(true);
      })
      .finally(() => {
        setLoadingGrowthRate(false);
      });
  }, [_cagrDateSelectOptions, btcPriceNow]);

  // Other parts of the app need this value for the logic
  React.useEffect(() => {
    form.change('showPrediction', !increaseValueByPercentage);
    resetPricePredictions();
  }, [increaseValueByPercentage]);

  React.useEffect(() => {
    if (!isNaN(cagrDateSelect)) {
      form.change('growth', cagrDateSelect);
    }
    if (isNaN(cagrDateSelect)) {
      const cagrPercentage = cagrDateSelect?.split('_')[0];
      form.change('growth', cagrPercentage);

    }
  }, [cagrDateSelect]);
  const setGrowthRateBasedOnTheOldPrice = (btcPriceNow, oldPrice) => {
    form.change('growth', Math.floor((btcPriceNow / oldPrice) * 100) - 100);
  };

  React.useEffect(() => {
    if (btcPriceNow && oldPrice) {
      setGrowthRateBasedOnTheOldPrice(btcPriceNow, oldPrice);
    }
  }, [oldPrice, btcPriceNow, customPrices]);

  // Set the initial value for the growth rate to the default option
  React.useEffect(() => {
    const defaultOption = getCAGRInitialValue(cagrDateSelectOptions);
    if (!customPrices) {
      form.change('growth', defaultOption);
      form.change('cagrDateSelect', defaultOption);
      
      if (defaultOption) {
        form.change('growth', defaultOption);
      }

      if (!increaseValueByPercentage) {
        setIncreaseValueByPercentage(true);
      }
    } else {
      if (increaseValueByPercentage) {
        setIncreaseValueByPercentage(false);
      }
    }
  }, [customPrices]);

  const resetPricePredictions = () => {
    form.change('pricePredictions', [
      { date: new Date(), value: btcPriceNow },
      { date: goalDate, value: '' },
    ]);
  };

  React.useEffect(() => {
    if (form && btcPriceNow) {
      if (!increaseValueByPercentage) {
        // always reset
        resetPricePredictions();
      } else {
        form.change('pricePredictions', [
          // new price today
          { date: new Date(), value: btcPriceNow },
          // copy all the prices in-between
          ...(pricePredictions.length >= 2
            ? pricePredictions.slice(1, pricePredictions.length)
            : []),
          // if we haven't got an entry for the goal date, add one
          ...(pricePredictions.find((pred) => isSameDay(pred.date, goalDate))
            ? []
            : [{ date: goalDate, value: '' }]),
        ]);
      }
    }
  }, [btcPriceNow, goalDate]); // eslint-disable-line react-hooks/exhaustive-deps

  function getCAGRInitialValue(cagrDateSelectOptions) {
    if (!cagrDateSelectOptions) {
      return 'CUSTOM';
    }
    const defaultOption = cagrDateSelectOptions.find((opt) => opt.default);
    return `${defaultOption.percentage}_${defaultOption.durationISO}`;
  }

  return (
    <>
      <Field
        name="pricePredictions"
        validate={(predictions) => {
          if ((predictions || []).filter(({ value }) => value < 0).length > 0) {
            return 'Negative prices not permitted.';
          }
        }}
      >
        {({ meta }) => (
          <StyledField
            style={{
              ...(cagrDateSelect === 'CUSTOM' && {
                marginBottom: '0.8rem',
              }),
            }}
            label={inputsData.pricePredictionsLabel}
            help={helpTexts.pricePredictions}
            upperRightContent={
              <Field name="customPrices" initialValue={false} type="checkbox">
                {({ input }) => (
                  <>
                    <StyledCustomPrices>
                      <label htmlFor="custom-prices-checkbox">
                        {inputsData.SetCustomPricesText}
                      </label>
                      <Checkbox
                        _id="custom-prices-checkbox"
                        onChange={input.onChange}
                        checked={input.checked}
                      />
                    </StyledCustomPrices>
                  </>
                )}
              </Field>
            }
          >
            {!customPrices && !loadingGrowthRate && (
              <>
                <Field
                  name="cagrDateSelect"
                  initialValue={getCAGRInitialValue(cagrDateSelectOptions)}
                >
                  {({ input }) => (
                    <Select {...input} id="goalDateSelect2">
                      {(cagrDateSelectOptions || []).map(
                        ({ durationISO, Label, percentage }, idx) => (
                          <option
                            key={idx}
                            value={`${percentage}_${durationISO}`}
                          >
                            {Label}: {percentage}%
                          </option>
                        )
                      )}
                    </Select>
                  )}
                </Field>
              </>
            )}
            {/* Loading Field - just for show */}
            {!customPrices && loadingGrowthRate && (
              <FormattedInput value="Loading..." format={(val) => `${val}`} />
            )}
            {customPrices && (
              <FormButton onClick={() => setShowCustomPricingModal(true)}>
                {inputsData.AddCustomPricingButtonText}
              </FormButton>
            )}
            <CustomPricingModal
              setCagrCustomDate={setCagrCustomDate}
              cagrCustomDate={cagrCustomDate}
              cagrCustomDatePercentage={cagrCustomDatePercentage}
              useStackingAmount={useStackingAmount}
              useCagrCustomDate={useCagrCustomDate}
              setUseCagrCustomDate={setUseCagrCustomDate}
              increaseValueByPercentageOnChange={(val) =>
                setIncreaseValueByPercentage(val)
              }
              inputsData={inputsData}
              increaseValueByPercentage={increaseValueByPercentage}
              open={showCustomPricingModal}
              onClose={() => setShowCustomPricingModal(false)}
            />
          </StyledField>
        )}
      </Field>
    </>
  );
};

export default PricePointsInput;
