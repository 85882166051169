import styled from 'styled-components';
import {Content as BaseContent, StyledModal as BaseStyledModal} from '../atoms/Modal';
import {H4, Paragraph} from '../atoms/Text';
import {LI, OL} from '../atoms/List';
import {Button} from '../atoms/Buttons';

export const StyledModal = styled(BaseStyledModal)`
  z-index: 999;
  background: rgba(102, 102, 102, 0.5);
  overflow: auto;
`

export const StyledContent = styled(BaseContent)`
  max-height: 90%;
  padding: 2.2rem 2rem 0 2rem;
  background: ${({theme}) => theme.colors.modalBackground};
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  @media screen and (min-width: ${({theme}) =>
    theme.breakpoints.sm.minWidth}) {
    width: 88rem;
    max-width: 90%;
    padding: 3.2rem 0 0 4rem;
  }
`

export const StyledTitle = styled(H4)`
  text-align: left;
  font-size: 3rem;
  width: 100%;
  color: ${({theme}) => theme.isDark ? '#fff' : 'inherit'};
  letter-spacing: 0.1rem;
  margin-bottom: 1.4rem;
`

export const StyledDescription = styled(Paragraph)`
  line-height: 1.25;
  font-size: 1.35rem;
  padding-bottom: 0.7rem;
`

export const StyledOrderedList = styled(OL)`
  text-align: left;
  width: 100%;
  padding-left: 2rem;
  margin-bottom: 8rem;
`

export const StyledLI = styled(LI)`
  padding-bottom: 0.2rem;
`

export const StyledButton = styled(Button)`
  font-weight: 800;
  padding: 0.5rem 1.1rem;
  font-size: 1.2rem;
  font-family: 'Inter', sans-serif;
  transition: 300ms all;
  border-radius: 999px;
  vertical-align: middle;
  display: inline-flex;
  gap: 1rem;

  &:hover {
    background: ${({theme}) => theme.colors.primaryHover};
  }
`

export const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  width: 50rem;
  --y-transform: 7.7rem;
  --x-transform: 3rem;
  right: unset;
  left: 50%;
  height: 30rem;
  transform: translate(calc(-50% - var(--x-transform) + 2.8rem), 5.4rem);
  @media screen and (min-width: ${({theme}) =>
    theme.breakpoints.md.minWidth}) {
    height: 40rem;
    transform: translate(var(--x-transform), var(--y-transform));
    right: 0;
    left: unset;
  }

  @media screen and (max-width: 370px) {
    height: 24rem;
  }
  @media screen and (max-width: 500px) {
    --x-transform: 2.76rem;
  }
`
