import {useEffect, useState} from 'react';
import {Offline} from "react-detect-offline";
import {useMitt} from 'react-mitt';
import RawOfflineModal from '../offline-modal/raw-offline-modal';

export default function OfflineModal({workOfflineSection}) {
  const [show, setShow] = useState(false);
  const [forcedShow, setForcedShow] = useState(false)
  const { emitter } = useMitt()
  useEffect(() => {
    emitter.on('show-offline-modal', () => setForcedShow(true))
  }, []);

  const data = {
    title: workOfflineSection?.title,
    description: workOfflineSection?.text,
    steps: workOfflineSection?.instructions?.map(i => i.text),
    buttonLabel: workOfflineSection?.goBackButtonText
  }

  if (forcedShow) {
    return <RawOfflineModal data={data} onClose={() => setForcedShow(false)} />
  }

  return <>
    {
      show ? <Offline polling={{url: "https://ipv4.icanhazip.com"}}>
        <RawOfflineModal data={data} onClose={() => setShow(false)} />
      </Offline> : null
    }
  </>
}
