import { CurrencyInput } from 'utils/currency'
import { Field } from "react-final-form"
import { StyledField } from "components/atoms/form"
import { useCalculation } from "components/content/calculator/calculationContext"

const StackingAmountField = ({ inputsData }) => {
    const { currency = "USD" } = useCalculation();

    return (
        <Field
            name="stackingAmount"
            type="number"
            validate={val => {
                if (Number(val) < 0) return "Value cannot be negative.";
            }}
            initialValue={inputsData.defaults.stackingAmount}
        >
            {({ input, meta }) => (
                <StyledField
                    label={inputsData.stackingAmountLabel}
                    error={meta.touched && meta.error}
                    help={inputsData.helpTexts.stackingAmount}
                >
                    <CurrencyInput
                        currency={currency}
                        {...input}
                        min={0}
                        $error={meta.touched && meta.error}
                    />
                </StyledField>
            )}
        </Field>
    );
}

export default StackingAmountField;
