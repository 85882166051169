import styled from 'styled-components';

const SwitchWrapper = styled.div`
  width: 100%;
  background: ${({ $primary, theme }) =>
    $primary
      ? theme.colors.primaryLowOpacity
      : theme.colors.tabSwitchUnselected};
  border-radius: 5px;
  height: 40px;
  position: relative;
`;

const LabelsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.button`
  flex: 1;
  background: none;
  border: none;
  color: ${({ theme, $selected, $primary }) =>
    $selected
      ? $primary
        ? theme.colors.textInverse
        : theme.colors.primary
      : theme.colors.text};
  cursor: pointer;
  height: 100%;
  z-index: 20;

  transition-property: color;
  transition-duration: 200ms;
`;

const PillWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ $primary }) => ($primary ? '0' : '.2rem')};
`;

const Pill = styled.div`
  display: ${({ $index }) => ($index >= 0 ? 'block' : 'hidden')};
  background: ${({ $primary, theme }) =>
    $primary ? theme.colors.primary : theme.colors.tabSwitchSelected};
  border-radius: 5px;
  z-index: 10;
  height: 100%;
  width: ${({ $count }) => 100 / $count}%;
  position: relative;
  left: ${({ $index, $count }) => $index * (100 / $count)}%;

  transition-property: left;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
`;

export default function TabSwitch({ tabs, value, onChange, primary }) {
  return (
    <SwitchWrapper $primary={primary}>
      <PillWrapper $primary={primary}>
        <Pill
          $index={tabs.findIndex((tab) => tab.value === value)}
          $count={tabs.length}
          $primary={primary}
        />
      </PillWrapper>
      <LabelsWrapper>
        {tabs.map((tab) => (
          <Label
            $selected={tab.value === value}
            key={tab.value}
            onClick={() => onChange(tab.value)}
            $primary={primary}
          >
            {tab.label}
          </Label>
        ))}
      </LabelsWrapper>
    </SwitchWrapper>
  );
}
