import 'react-toggle/style.css';

import { darken, rgba } from 'polished';

import { Box } from 'atomic-layout';
import { Paragraph } from 'components/atoms/Text';
import ReactToggle from 'react-toggle';
import styled from 'styled-components';

const StyledReactToggle = styled(ReactToggle)`
  .react-toggle-track {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }

  &.react-toggle--checked {
    .react-toggle-track {
      background-color: ${({theme}) => theme.colors.primary};
    }

    .react-toggle-thumb {
      border-color: ${({theme}) => theme.colors.primary};
    }
  }


    &:not(.react-toggle--checked) {
    .react-toggle-track {
      background-color: #d1d1d1;
    }

    .react-toggle-thumb {
      border-color: #d1d1d1;
    }
  }

  &:not(.react-toggle--checked):hover {
    .react-toggle-track {
      background-color: #c6c6c6;
    }
  }

  &.react-toggle--checked:hover {
    .react-toggle-track {
      background-color: ${({theme}) => theme.colors.primary};
    }
  }

  .react-toggle-thumb {
    background-color: #fff;
      // border-color: ${({theme}) => theme.colors.primary};
  }
`;

const StyledToggleParagraph = styled(Paragraph)({
  maxHeight: '41px',
  lineHeight: 1.5,
})

const Toggle = ({ leftLabel, rightLabel, ...props }) => {
  return (
    <Box flex flexDirection="row" alignItems="center" gap={1}>
      {leftLabel && <Paragraph $padding="initial">{leftLabel}</Paragraph>}
      <StyledReactToggle icons={false} {...props} />
      {rightLabel && <StyledToggleParagraph $padding="initial">{rightLabel}</StyledToggleParagraph>}
    </Box>
  );
};

export default Toggle;
