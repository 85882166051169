import styled from 'styled-components';
import {useState} from 'react';

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.text};
  ${({ focused }) => focused ? 'outline: 1px solid' : ''};
  padding: 1px;
  width: 15px;
  height: 15px;
  position: relative;
  margin: 0;
`

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
`

export default function Checkbox({ checked, onChange, _id }) {
  const [focused, setFocused] = useState(false)

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
  }

  const handleClick = () => {
    setFocused(false)
  }

  return <StyledCheckboxContainer onClick={handleClick} focused={focused}>
    <HiddenInput onFocus={onFocus} onBlur={onBlur} id={_id} checked={checked} onChange={onChange} type="checkbox"/>
    {checked && <svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
      <path d="M43 11L16.875 37L5 25.1818" stroke="currentColor" stroke-width="10" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>}
  </StyledCheckboxContainer>
}
