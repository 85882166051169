import axios from 'axios';
import { formatDateForHistoricApi } from '../date';

export async function fetchHistoricBtcPrice({ date }) {
  const res = await axios.get(`/api/satsgoal-historic?date=${date}`);
  return res.data;
}

export async function fetchMultipleHistoricBtcPrice({ dates }) {
  const promises = dates.map(({ year, month, day }) =>
    fetchHistoricBtcPrice({
      date: formatDateForHistoricApi({ year, month, day }),
    })
  );

  const res = await Promise.all(promises);

  return res;
}
