import * as duration from 'duration-fns';

import { DatePicker, StyledField } from 'components/atoms/form';
import { Field, useForm, useFormState } from 'react-final-form';
import React, { useEffect } from 'react';

import { Select } from 'components/atoms/form/select';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import { dateOnly } from 'utils/date';
import Checkbox from '../../../atoms/Checkbox';
import { StyledCustomPrices } from '../../../atoms/CustomPrice';

const CustomDateCheckbox = ({ inputsData }) => {
  return <Field
      name="customDate"
      initialValue={false}
      type="checkbox"
  >
    {({ input }) =>
        <>
          <StyledCustomPrices>
            <label htmlFor="custom-date-checkbox">{inputsData.setCustomDate}</label>
            <Checkbox _id="custom-date-checkbox" onChange={input.onChange} checked={input.checked} />
          </StyledCustomPrices>
        </>
    }
  </Field>

}

export default function GoalDateField({ inputsData }) {
  const {
    helpTexts,
    customGoalDateOptionLabel,
    goalDateSelectOptions = [],
  } = inputsData;
  const form = useForm();
  const { values } = useFormState();
  const { goalDateSelect, useStackingAmount = true, customDate } = values;

  useEffect(() => {
    if (goalDateSelect !== 'CUSTOM') {
      const period = duration.parse(goalDateSelect);
      const newDate = duration.apply(new Date(), period);
      form.change('goalDate', newDate);
    }
  }, [goalDateSelect]);

  return (
    <>
      {!customDate && <Field
          name="goalDateSelect"
          initialValue={
              goalDateSelectOptions?.find((opt) => opt.default)?.durationISO ||
              'CUSTOM'
          }
      >
        {({input, meta}) => (
            <StyledField
                id="goalDateSelect"
                label={
                  useStackingAmount
                      ? inputsData.goalDateLabelPredictByInvestmentAmount
                      : inputsData.goalDateLabelPredictByGoal
                }
                error={meta.touched && meta.error}
                help={
                  useStackingAmount
                      ? helpTexts.goalDateSelectPredictByInvestmentAmount
                      : helpTexts.goalDateSelect
                }
                upperRightContent={<CustomDateCheckbox inputsData={inputsData}/>}
            >
              <Select {...input} id="goalDateSelect">
                {(goalDateSelectOptions || []).map(
                    ({durationISO, label}, idx) => (
                        <option key={idx} value={durationISO}>
                          {label}
                        </option>
                    )
                )}
              </Select>
            </StyledField>
        )}
      </Field>}
      <Field name="goalDate">
        {({ input, meta }) =>
          customDate ? (
            <StyledField
              id="goalDate"
              label={
                useStackingAmount
                  ? inputsData.customGoalDatePickerLabelPredictByInvestment
                  : inputsData.customGoalDatePickerLabelPredictByGoal
              }
              error={meta.touched && meta.error}
              help={
                useStackingAmount
                  ? helpTexts.goalDatePredictByInvestmentAmount
                  : helpTexts.goalDate
              }
              upperRightContent={<CustomDateCheckbox inputsData={inputsData} />}
            >
              <DatePicker
                  id="goalDate"
                  {...input}
                  minDate={dateOnly(addDays(new Date(), 1))}
                  maxDate={addYears(dateOnly(new Date()), 50)}
                  style={{ border: 'none' }}
              />
            </StyledField>
          ) : null
        }
      </Field>
    </>
  );
}
