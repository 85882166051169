import styled from 'styled-components';
import {
  Content as BaseContent,
  StyledModal as BaseStyledModal,
} from '../atoms/Modal';
import { H4, Paragraph } from '../atoms/Text';
import { LI, OL } from '../atoms/List';
import { Button } from '../atoms/Buttons';

export const StyledModal = styled(BaseStyledModal)`
  z-index: 999;
  background: rgba(102, 102, 102, 0.5);
  overflow: auto;
`;

export const StyledContent = styled(BaseContent)(({ theme, $width }) => ({
  maxHeight: '80%',
  padding: '2.2rem 2rem 2rem 2rem',
  background: theme.colors.modal2Background,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '10px',
  width: '90%',
  [`@media screen and (min-width: ${theme.breakpoints.md.minWidth})`]: {
    width: $width || 'auto',
    maxWidth: '90%',
  },
}));

export const StyledHeader = styled.div(({$col}) => ({
  display: 'flex',
  flexDirection: $col ? 'column' : 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '1.4rem',
  alignItems: 'center',
  gap: $col ? '1rem' : 'initial',
}));

export const StyledTitle = styled(H4)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '2.1rem',
  width: '100%',
  color: theme.isDark ? '#fff' : 'inherit',
  letterSpacing: '0.1rem',
  ['@media screen and (max-width: 700px)']: {
    fontSize: '1.5rem',
    marginRight: '0.1rem',
  },
}));
export const StyledSubTitle = styled(H4)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '1.4rem',
  width: '100%',
  color: theme.colors.primary,
  fontWeight: 600,
  letterSpacing: '0.1rem'
}));
