import ReactDOM from 'react-dom';
import { StyledModal } from '../atoms/Modal';
import { StyledContent, StyledHeader, StyledTitle } from './index';
import { FormButton } from '../atoms/form/formButton';
import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  FormattedInput,
  PercentInput,
  StyledField,
} from '../atoms/form';
import { Field } from 'react-final-form';
import PredictionInputs from '../content/calculator/inputs/predictionInputs';
import styled from 'styled-components';
import { dateOnly, getYMD, isBeforeBitcoinExistence } from '../../utils/date';
import Checkbox from '../atoms/Checkbox';
import { BITCOIN_API_BEGIN_DATE } from '../../utils/constants';

const StyledCheckboxContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.3rem;
`;

const StyledCustomDateContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledCustomDateInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const StyledPercentageIncrease = styled.div`
  margin-bottom: 1rem;
`;

export default function CustomPricingModal({
  inputsData,
  cagrCustomDate,
  setCagrCustomDate,
  cagrCustomDatePercentage,
  useStackingAmount,
  open,
  onClose,
  increaseValueByPercentage,
  increaseValueByPercentageOnChange,
  useCagrCustomDate,
  setUseCagrCustomDate,
}) {
  const [hasEverOpened, setHasEverOpened] = useState(false);
  useEffect(() => {
    if (open) {
      setHasEverOpened(true);
    }
  }, [open]);

  const onModalClick = (e) => {
    if (e.target.classList.contains('close-on-click')) {
      onClose();
    }
  };

  return (
    hasEverOpened &&
    ReactDOM.createPortal(
      <StyledModal
        className="close-on-click"
        onClick={onModalClick}
        style={{
          display: open ? 'flex' : 'none',
        }}
      >
        <StyledContent>
          <StyledHeader>
            <StyledTitle>{inputsData.CustomPricingModalTitle}</StyledTitle>
            <FormButton
              style={{ maxHeight: '60px', marginLeft: '3rem' }}
              onClick={onClose}
            >
              Close
            </FormButton>
          </StyledHeader>
          <StyledFormContainer>
            <StyledCheckboxContainer>
              <label htmlFor="increase-by-percentage-checkbox">
                {inputsData.CustomPricingModalIncreaseValueByPercentage}
              </label>
              <Checkbox
                _id="increase-by-percentage-checkbox"
                checked={increaseValueByPercentage}
                onChange={(e) =>
                  increaseValueByPercentageOnChange(e.target.checked)
                }
              />
            </StyledCheckboxContainer>
            {!increaseValueByPercentage && (
              <StyledCheckboxContainer style={{ marginBottom: '1.5rem' }}>
                <label htmlFor="custom-date">
                  {inputsData.annualGrowthRateFromDate}
                </label>
                <Checkbox
                  _id="custom-date"
                  checked={useCagrCustomDate}
                  onChange={(e) => setUseCagrCustomDate(e.target.checked)}
                />
              </StyledCheckboxContainer>
            )}
            <div>
              {!useCagrCustomDate && !increaseValueByPercentage && (
                <PredictionInputs inputsData={inputsData} />
              )}
              {useCagrCustomDate && (
                <StyledCustomDateContainer
                  id="cagrCustomDate"
                  // label={
                  //     (useStackingAmount
                  //         ? inputsData.customGoalDatePickerLabelPredictByInvestment
                  //         : inputsData.customGoalDatePickerLabelPredictByGoal) +
                  //     (cagrCustomDatePercentage ? ' - ' + cagrCustomDatePercentage + '%' : '')
                  // }
                >
                  <StyledCustomDateInputContainer>
                    <div>{inputsData.customDate}</div>
                    <DatePicker
                      minDate={BITCOIN_API_BEGIN_DATE}
                      // maxDate={new Date()}
                      id="cagrCustomDate"
                      value={cagrCustomDate}
                      onChange={(val) => {
                        if (isBeforeBitcoinExistence(getYMD(val))) {
                          alert(inputsData.beforeBitcoinExistenceMessage);
                        } else {
                          setCagrCustomDate(val);
                        }
                      }}
                      maxDate={dateOnly(new Date())}
                      style={{ border: 'none' }}
                    />
                  </StyledCustomDateInputContainer>
                  {cagrCustomDatePercentage && (
                    <StyledCustomDateInputContainer>
                      <div>{inputsData.annualGrowthRate}</div>
                      <FormattedInput
                        value={cagrCustomDatePercentage}
                        readOnly
                        format={(val) => `${val}%`}
                      />
                    </StyledCustomDateInputContainer>
                  )}
                </StyledCustomDateContainer>
              )}
              {Boolean(increaseValueByPercentage) && (
                <>
                  <StyledPercentageIncrease>
                    {inputsData.percentageIncrease}
                  </StyledPercentageIncrease>
                  <Field
                    name="growth"
                    type="number"
                    initialValue={inputsData.defaults.growthRate || undefined}
                    validate={(val) => {
                      if (Number(val) == -100)
                        return 'Cannot lose 100% value in 1 year.';
                    }}
                  >
                    {({ input }) => <PercentInput {...input} />}
                  </Field>
                </>
              )}
            </div>
          </StyledFormContainer>
        </StyledContent>
      </StyledModal>,
      document?.body
    )
  );
}
