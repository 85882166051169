import { Box } from 'atomic-layout';
import { H4 } from './Text';
import { lighten } from 'polished';
import styled from 'styled-components';

export const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => `${theme.colors.background}cc`};
`;

export const Content = styled(Box)`
  z-index: 51;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => `${theme.colors.background}`};
  padding: 0 1rem;
  width: 90%;
  max-height: 40rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  padding-top: 0.1rem;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.minWidth}) {
    width: 40rem;
  }
`;

export const CloseModal = styled.div`
  position: sticky;
  top: 0;
  z-index: 55;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  padding: 1rem;
  margin-bottom: 1rem;
  background: ${({ theme }) => theme.colors.tableHead};
`;

export const CloseButton = styled.button`
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textInverse};
  font-size: inherit;
  font-weight: bold;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.primary};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;

  &:hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
`;

export const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 54;
  width: 100%;
  padding: 1rem;
  border-radius: 0 0 5px 5px;
  background: transparent;
`;

const Modal = ({ title, onClose, children }) => {
  return (
    <StyledModal>
      <Content>
        <CloseModal>
          <H4>{title}</H4>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </CloseModal>
        {children}
        <ModalFooter />
      </Content>
    </StyledModal>
  );
};

export default Modal;
